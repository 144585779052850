import React from "react";
import { Link } from "gatsby";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const NotFoundPage = () => {
  return (
    <>
      <SEO
        customTitle="404"
        customDescription="404 page"
        customKeywords="404"
        path="/404"
      />

      <Layout className="not-found w-full relative">
        <section className="not-found__container w-full relative flex flex-col items-center justify-center">
          <h1 className="f1 mb-v5">404</h1>
          <p className="f3">
            This page must’ve got lost in the wash.
            <br /> Go to
            <Link to="/" className="underline">
              home
            </Link>
            or see our
            <Link to="/products" className="underline">
              products.
            </Link>
          </p>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default NotFoundPage;
